//------------------------------------------------------------------------------
declare const RESOLVE, REJECT
//------------------------------------------------------------------------------
import  { Component                                 } from '@angular/core'
import  { ListDiv               , ListComp
        , ItemDiv               , ItemModal
        , RgnService                                } from './service'
//------------------------------------------------------------------------------
@Component({selector:'RgnLst', template:ListDiv})
export class RgnLst extends ListComp {
    //--------------------------------------------------------------------------
    constructor( public service:RgnService ) { super() }
    comp_type                   = 'main'
    //--------------------------------------------------------------------------
    listDefn            :any    =
        { name          : {lab:'name'               , wide: 5}
        , cur_name      : {lab:'currency'           , wide: 3}
//         , shp_name      : {lab:'shipping method'    , wide: 4}
        , free_ship_threshold
                        : {lab:'free shipping'      , wide: 2, typ:'decimal'}
        , sort_no       : {lab:'sort'               , wide: 1, typ:'integer'}
        }
    //--------------------------------------------------------------------------
    onLoad_task                 = 'load'
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
@Component({selector:'Rgn', template:ItemDiv})
export class Rgn extends ItemModal {
    //--------------------------------------------------------------------------
    constructor( public service:RgnService ) { super() }
    modal_size                  = 'medium'
    //--------------------------------------------------------------------------
    itemDefn            :any    =
        { HIDE          : ['id','update_date']
        , name          : {lab:'name'               , wide: 8               , copyCheck:true}
        , sort_no       : {opt:'sort'               , wide: 4, typ:'integer'}

        , cur_id        : {lab:'currency'           , wide: 4, cod:'cur'    }
//         , shp_id        : {lab:'shipping method'    , wide: 4, cod:'shp'    }
        , free_ship_threshold
                        : {opt:'free shipping threshold'
                                                    , wide: 4, typ:'decimal'}
        }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
