//------------------------------------------------------------------------------
declare const RESOLVE, REJECT
//------------------------------------------------------------------------------
import  { Component                                 } from '@angular/core'
import  { SelectDiv, SelectModal, KntService        } from './service'
//------------------------------------------------------------------------------
@Component({selector:'SelectKntAdr', template:SelectDiv})
export class SelectKntAdr extends SelectModal {
    //--------------------------------------------------------------------------
    constructor( public service:KntService ) { super() }
    modal_size                  = 'large'
    //--------------------------------------------------------------------------
    listDefn            :any    =
        { name          : {lab:'name'               , wide: 6}
        , descr         : {lab:'description'        , wide: 6}
        , street        : {lab:'street'             , wide: 6}
        , city          : {lab:'city'               , wide: 6}
        , state         : {lab:'state'              , wide: 4}
        , postcode      : {lab:'postcode'           , wide: 2}
        , lnd_name      : {lab:'country'            , wide: 4}
        , email         : {lab:'email'              , wide: 6}
        , tel_num       : {lab:'phone'              , wide: 4}
        , default       : {lab:'dflt'               , wide: 1, typ:'flag'}
        , preferred     : {lab:'pref'               , wide: 1, typ:'flag'}
        }
    //--------------------------------------------------------------------------
    onLoad_task                 = 'adr_search'
    //--------------------------------------------------------------------------
    onInitP() {
        this.setHeader('select address')
        this.onInitSelect()

        this.dft.knt_id         = this.ctx.knt_id
        return this.loadAndFocusP()
    }
    loadResult(list) {
        return list.foundItems || list || []
    }
    //--------------------------------------------------------------------------
    onSearchP(key:string, values:any) {
        return this.busyServiceEmitP('adr_search', values)
        .then((res:any) => res.foundItems ? this.loadWithP(res.foundItems) : this.onReturnResP(res) )
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
