//------------------------------------------------------------------------------
export function exchToEuro(value:number, rate:number) {
    if (!rate    )              { throw new Error(`invalid exchrate (${rate})`)}
    if (!value   )              { return     0 }
    if (rate == 1)              { return value }
    if (rate == 100000000)      { return value }
    return Math.round((value * 100000000.0) / rate)
}
export function valuebyqty(value:number, qty:number) {
    if (qty == 0 || value == 0) { return     0 }
    if (qty == 1000)            { return value }
    return Math.round((value * qty) /  1000.0)
}
export function percentage(value:number, pct:number) {
    if (pct == 0 || value == 0) { return     0 }
    if (pct == 10000)           { return value }
    return Math.round((value * pct) / 10000.0)
}
export function percentof(value:number, tot:number) {
    if (tot == 0 || value == 0) { return     0 }
    if (tot == value)           { return 10000 }
    return Math.round((value * 10000.0) / tot)
}
export function addtaxpct(value:number, pct:number) {
    if (pct)                    { return value + percentage(value, pct) }
    else                        { return value                          }
}
export function subtaxpct(value:number, pct:number) {
    if (pct)                    { return percentof(value, 10000 + pct)  }
    else                        { return value                          }
}
//------------------------------------------------------------------------------
export function recalcPst(pst:any) {
    pst.exchrate                = pst.exchrate  || 100000000
    pst.taxpct                  = pst.taxpct    || 0

    function set_totals() {
        pst.acc_netto           = exchToEuro(pst.netto      , pst.exchrate  )
        pst.acc_brutto          = exchToEuro(pst.brutto     , pst.exchrate  )
        pst.dsc_net_tot         = valuebyqty(pst.dsc_net    , pst.qty       )
        pst.dsc_brt_tot         = valuebyqty(pst.dsc_brt    , pst.qty       )
        pst.netto_tot           = valuebyqty(pst.netto      , pst.qty       )
        pst.brutto_tot          = valuebyqty(pst.brutto     , pst.qty       )
        pst.acc_netto_tot       = valuebyqty(pst.acc_netto  , pst.qty       )
        pst.acc_brutto_tot      = valuebyqty(pst.acc_brutto , pst.qty       )
        pst.ols_netto_tot       = valuebyqty(pst.ols_netto  , pst.qty       )
        pst.ols_brutto_tot      = valuebyqty(pst.ols_brutto , pst.qty       )
    }

    // not our data...
    pst.ols_netto               = pst.ols_netto  || 0
    pst.ols_brutto              = pst.ols_netto  || 0

    if (pst.wsale_sw === true || pst.wsale_yn == 'y') {
        // basis
        pst.dsc_net             = pst.dsc_net    || 0
        pst.netto               = pst.netto      || 0
        // computed from basis
        pst.dsc_brt             = addtaxpct (pst.dsc_net    , pst.taxpct    )
        pst.brutto              = addtaxpct (pst.netto      , pst.taxpct    )
        set_totals()
        pst.price               = pst.netto
        pst.discount            = pst.dsc_net
        pst.total               = pst.netto_tot
    } else {
        // basis
        pst.dsc_brt             = pst.dsc_brt    || 0
        pst.brutto              = pst.brutto     || 0
        // computed from basis
        pst.dsc_net             = subtaxpct (pst.dsc_brt    , pst.taxpct    )
        pst.netto               = subtaxpct (pst.brutto     , pst.taxpct    )
        set_totals()
        pst.price               = pst.brutto
        pst.discount            = pst.dsc_brt
        pst.total               = pst.brutto_tot
    }
    return pst
}
//------------------------------------------------------------------------------
