//------------------------------------------------------------------------------
declare const RESOLVE, REJECT
//------------------------------------------------------------------------------
import  { Component                                 } from '@angular/core'
import  { ItemDiv, ItemComp, VrgService             } from './service'
//------------------------------------------------------------------------------
@Component({selector:'VrgAdr', template:ItemDiv})
export class VrgAdr extends ItemComp {
    //--------------------------------------------------------------------------
    constructor( public service:VrgService ) { super() }
    comp_type                   = 'tab'
    //--------------------------------------------------------------------------
    baseDefn            :any    =
        { HIDE          : ['d_id','d_knt_id','i_id','i_knt_id']
        , d_name        : {lab:'delivery name'      , wide: 6, section:'delivery address'}
        , d_descr       : {opt:'descr'              , wide: 6}

        , d_street      : {lab:'street'             , wide: 3}
        , d_city        : {lab:'city'               , wide: 3}
        , d_postcode    : {lab:'postcode'           , wide: 3}
        , d_lnd_id      : {lab:'country'            , wide: 3, cod:'lnd'    }

        , d_email       : {lab:'email'              , wide: 3}
        , d_tel_num     : {opt:'telephone'          , wide: 3}
        , d_rsr_id      : {opt:'location'           , wide: 3, cod:'rsr'    }
        , d_load        : {lab:'select address'     , wide: 3, btn:this.onAddressP}

        , i_name        : {lab:'invoice name'       , wide: 6, section:'invoice address'}
        , i_descr       : {opt:'descr'              , wide: 6}

        , i_street      : {lab:'street'             , wide: 3}
        , i_city        : {lab:'city'               , wide: 3}
        , i_postcode    : {lab:'postcode'           , wide: 3}
        , i_lnd_id      : {lab:'country'            , wide: 3, cod:'lnd'    }

        , i_email       : {lab:'email'              , wide: 3}
        , i_tel_num     : {opt:'telephone'          , wide: 3}
        , i_skip        : {lab:''                   , wide: 3, typ:'skip'   }
        , i_load        : {lab:'select address'     , wide: 3, btn:this.onAddressP}
        }
    //--------------------------------------------------------------------------
    onRead_task                 = 'adr_read'
    onSave_task                 = 'adr_save'
    onDelete_task               = 'adr_dele'
    //--------------------------------------------------------------------------
    onInitP() {
        this.itemDefn           = this.clone(this.baseDefn)
        if (!this.ctx.canEdit) {
            this.itemDefn.RDO   = true
        }
        this.defineItem         (this.itemDefn      )
        if (this.ctx.canEdit) {
            this.buttonOk       (this.onSubmitP     )
            this.formSubmit     (this.onSubmitP     )
        }
        let args
        if (this.ctx.vrg_id) {
            args                = { vrg_id          : this.ctx.vrg_id }
        } else {
            args                = { typ             : this.ctx.typ
                                  , knt_id          : this.cache.vrg.cus_id
                                  , adr_id_dlv      : this.cache.vrg.adr_id_dlv
                                  , adr_id_inv      : this.cache.vrg.adr_id_inv
                                  }
        }

        return this.readP(args)
        .then(() => this.setFocusP() )
    }
    //--------------------------------------------------------------------------
    readResult(res:any) {
        this.cacheIds(res)
        return res
    }
    //--------------------------------------------------------------------------
    cacheIds(res:any) {
        if (!this.ctx.vrg_id) {
            this.cache.vrg.adr_id_dlv
                                = res.d_id
            this.cache.vrg.adr_id_inv
                                = res.i_id
        }
    }
    //--------------------------------------------------------------------------
    onAddressP(key:string) {
        // key is either 'd_load' or 'i_load'
        let pfx                 = key.slice(0,2)
        let ctx                 = { knt_id:this.cache.vrg.cus_id }
        return this.callComponent('SelectKntAdr', ctx)
        .then((res:any) => {
            if (!res)           { return REJECT() }
            let adr_id          = res.id
            let vals            = {}
            for (let key in res) {
                vals[pfx + key] = res[key]
            }
            return this.setItemValuesP(vals, true)
            .then(() => {
                if (this.ctx.vrg_id)
                                { return RESOLVE() }
                if (pfx == 'd_') {
                    let lnd_id  = this.cache.vrg.lnd_id
                    if (lnd_id && res.lnd_id != lnd_id)
                                { return REJECT('delivery address country does not match document delivery country') }
                    this.cache.vrg.adr_id_dlv
                                = adr_id
                } else {
                    this.cache.vrg.adr_id_inv
                                = adr_id
                }
                return RESOLVE()
            })
        })
    }
    //--------------------------------------------------------------------------
    onSubmitP(key:string, values:any) {
// this.log('onSubmitP:', values)
        return this.ctx.vrg_id
            ? this.onSaveP    (key, values)
            : this.onValidateP(key, values)
    }
    //--------------------------------------------------------------------------
    onSaveP(key:string, values:any) {
        let args                = this.clone(values,
            { vrg_id            : this.ctx.vrg_id
            , vrg_typ           : this.ctx.typ
            , vrg_lnd_id        : this.cache.vrg.lnd_id
            })
        return this.busyServiceEmitP('adr_save', args)
        .then((res:any) => {
            this.item           = res
            this.messageSaved()
            return RESOLVE()
        })
    }
    //--------------------------------------------------------------------------
    onValidateP(key:string, values:any) {
        let args                = this.clone(values,
            { vrg_id            : 0
            , vrg_typ           : this.ctx.typ
            , vrg_lnd_id        : this.cache.vrg.lnd_id
            , knt_id            : this.cache.vrg.cus_id
            })
        return this.busyServiceEmitP('adr_save', args)
        .then((res:any) => {
            this.item           = res
            this.cacheIds(res)
            if (!this.service.revealTab('pst')) {
                this.service.showValidTabs()
            }
            this.messageValid()
            return RESOLVE()
        })
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
