//------------------------------------------------------------------------------
let oneMinute                   =   60 * 1000
let oneMegabyte                 = 1024 * 1024
//------------------------------------------------------------------------------
export class Config {
    name                :string = ''
    api                         = window.location.origin
    menu                        = { compname:'', ctx:{} }
    socket                      =
        { heartrate             :   30 * 1000       // milliseconds
        , pingInterval          :   60 * 1000       // milliseconds
        , pingTimeout           :  600 * 1000       // milliseconds
        , timeout               :   90 * 1000       // milliseconds
        }
    doubleclick_timer           =  250              // milliseconds
    max_image_upload_size       =    2 * oneMegabyte
    max_file_upload_size        =   20 * oneMegabyte
    listChunkSize               =  100              // items
}
//------------------------------------------------------------------------------
