//------------------------------------------------------------------------------
declare const RESOLVE, REJECT, is, jQuery, regexTest
//------------------------------------------------------------------------------
export function makeList(s:any) {
    return is.array(s)  ? s
         : is.empty(s)  ? []
                        : s.toString().trim(s).split(/[,\s]+/)
}
//------------------------------------------------------------------------------
const isDate                    = (o:any) => Object.prototype.toString.call(o).slice(8,-1) == 'Date'
//------------------------------------------------------------------------------
const num2                      = (n)      => int2str(n, 2)
const num3                      = (n)      => int2str(n, 3)
const num4                      = (n)      => int2str(n, 4)
const int2str                   = (n, len) => ('00000000000000000000' + n).slice(-len)
//------------------------------------------------------------------------------
function decimal(value, sep='.', dp=2) {
    value                       = parseInt(value) || 0
    sep                         = regexTest(sep, 'EUR', '€', ',') ? ',' : '.'
    let exp                     = 10 ** dp
    let sign                    = value < 0 ? '-' : ''
    value                       = Math.abs(value)
    let [ int, dec ]            = [ Math.floor(value / exp), value % exp ]
    return sign + int + sep + int2str(dec, dp)
}
//------------------------------------------------------------------------------
function format_date(date:any, format:string) {
    if (date === undefined || date === null || date == '')
                                { return '' }
    date                        = isDate(date) ? date : new Date(date)
    return format.replace(/%[%\w]/g, (found:any) => {
        try             { return format_date_sub[found[1]](date) }
        catch(err:any)  { return found[1]                        }
    })
}
//------------------------------------------------------------------------------
const format_date_sub           =
{ d: (date:any) => num2(date.getDate()          )   // day of month : 00-31
, H: (date:any) => num2(date.getHours()         )   // hours        : 00-23
, i: (date:any) => num3(date.getMilliseconds()  )   // milliseconds : 000-999
, m: (date:any) => num2(date.getMonth() + 1     )   // month        : 00-12
, M: (date:any) => num2(date.getMinutes()       )   // minutes      : 00-59
, S: (date:any) => num2(date.getSeconds()       )   // seconds      : 00-59
, Y: (date:any) => num4(date.getFullYear()      )   // year         : 0000-9999
}
//------------------------------------------------------------------------------
function thousands(value:any) {
    return (value || '0').toString().replace(/\d{1,3}(?=(\d{3})+(?!\d))/g,'$&,')
}
//------------------------------------------------------------------------------
function boolean2flag(value:any) {
    return (regexTest(value, 'y', 'true' )) ? '*'
         : (regexTest(value, 'n', 'false')) ? '\xa0'
         : (value == undefined            ) ? '\xa0'
                                            : value
}
//------------------------------------------------------------------------------
function cod2human(value:any) {
    return value || 0
}

function human2cod(value:any) {
    if (value === null)         { return undefined }
    let test                    = parseInt(value)
    if (isNaN(test))            { return value }
    else                        { return test  }
}
//------------------------------------------------------------------------------
function code2human(value:any) {
    return value.toString().replace(/(\d{4})/g,'$1 ').trim()
}

function human2code(value:any) {
    if (value === null)         { return undefined }
    return (value || '').toString().replace(/\s+/g,'')
}
//------------------------------------------------------------------------------
const dateTest                  = RegExp(
      '^'
    + '([0-2]?[0-9]|3[01])'     // day
    + '([./-](0?[0-9]|1[012])'  // month
      + '([./-]([0-9]{4}))?'    // year
    + ')?'
    + '$'
    )

function date2human(value:any) {
    return format_date(value, '%d.%m.%Y')
}

function human2date(value:any) {
    let bits                    = dateTest.exec(value)
    if (!bits)                  { return undefined }
    let now                     = new Date()
    let tz                      = now.getTimezoneOffset()
    let dD                      = bits[ 1] ? +bits[ 1]: now.getDate    ()
    let dM                      = bits[ 3] ? +bits[ 3]: now.getMonth   ()   + 1
    let dY                      = bits[ 5] ? +bits[ 5]: dM > now.getMonth() + 3
                                                      ? now.getFullYear()   - 1
                                                      : now.getFullYear()
    let res                     = new Date(`${num4(dY)}-${num2(dM)}-${num2(dD)}`)
    if (isNaN(res.valueOf()))   { return undefined }
    else                        { return res }
}
//------------------------------------------------------------------------------
const datetimeTest              = RegExp(
      '^'
    + '([0-2]?[0-9]|3[01])'     // day
    + '([./-](0?[0-9]|1[012])'  // month
      + '([./-]([0-9]{4}))?'    // year
    + ')?'
    + '( ([01]?[0-9]|2[0-3])'   // hours
      + '(:([0-5]?[0-9])'       // minutes
        + '(:([0-5]?[0-9])'     // seconds
//           + '(\\.([0-9]{1,3}))?'// milliseconds
        + ')?'
      + ')?'
    + ')?'
    + '$'
    )

function datetime2human(value:any) {
    return format_date(value, '%d.%m.%Y %H:%M:%S')
}

function human2datetime(value:any) {
// console.log(`[human2datetime] (${value}:${is.type(value)})`)
    if (value == '00.00.0000 00:00:00') {
        return null
    }
    let bits                    = datetimeTest.exec(value)
    if (!bits)                  { return undefined }
    let now                     = new Date()
    let tz                      = now.getTimezoneOffset()
    let dD                      = bits[ 1] ? +bits[ 1]: now.getDate    ()
    let dM                      = bits[ 3] ? +bits[ 3]: now.getMonth   ()   + 1
    let dY                      = bits[ 5] ? +bits[ 5]: dM > now.getMonth() + 3
                                                      ? now.getFullYear()   - 1
                                                      : now.getFullYear()
    let tH                      = bits[ 7] ? +bits[ 7]: 0
    let tM                      = bits[ 9] ? +bits[ 9]: 0
    let tS                      = bits[11] ? +bits[11]: 0





    // no idea why we have to allow for the timezone in 'human2timestamp'
    // but not in 'human2datetime'
    let res                     = new Date(dY, dM - 1, dD, tH, tM, tS)
// console.log(`[human2datetime] res:${res}:${is.type(res)}`)
    if (isNaN(res.valueOf()))   { return undefined }
    else                        { return res }
}
//------------------------------------------------------------------------------
const decimalTest               = RegExp(
      '^'
    + '([-+])?'                 // sign
    + '([0-9]+)?'               // integer
    + '[.,]?'
    + '([0-9]{1,2})?'           // decimal
    + '$'
    )

function decimal2human(value:any) {
    return decimal(value, '.', 2)
}

function human2decimal(value:any) {
    if (value === null)         { return 0 }
    let bits                    = decimalTest.exec(value)
    if (!bits)                  { return undefined }
    if (bits[2] && bits[2].length > 13)
                                { return undefined }
    let sign                    = bits[1] == '-' ? -1 : 1
    let int                     = bits[2] ? +bits[2] : 0
    let dec                     = bits[3] ? +bits[3] : 0
    if (bits[3] && bits[3].length < 2) {
        dec                    *= 10
    }
    return sign * (int * 100 + dec)
}

function decimal2criteria(value:any) {
// console.log('[decimal2criteria]', value)
    return value === null || value == '!' || value == '-' ? value : decimal(value, '.', 2)
}

function criteria2decimal(value:any) {
// console.log('[criteria2decimal]', value)
    if (!value || value == '!' || value == '-') {
        return value || null
    } else {
        return human2decimal(value) || null
    }
}

function decimal2none(value:any) {
    if (!value)                 { return '' }
    return decimal(value, '.', 2)
}
//------------------------------------------------------------------------------
const exchrateTest              = RegExp(
      '^'
    + '([0-9]+)?'               // integer
    + '[.,]?'
    + '([0-9]{1,8})?'           // decimal
    + '$'
    )

function exchrate2human(value:any) {
    return decimal(value, '.', 8)
}

function human2exchrate(value:any) {
    if (value === null)         { return 0 }
    let bits                    = exchrateTest.exec(value)
    if (!bits)                  { return undefined }
    if (bits[1] && bits[1].length > 4)
                                { return undefined }
    let int                     = (bits[1] || '')
    let dec                     = (bits[2] || '')

    return  +(int + ((dec + '00000000').slice(0,8)))
}

function exchrate2none(value:any) {
    if (!value)                 { return '' }
    return decimal(value, '.', 8)
}
//------------------------------------------------------------------------------
function file2human(value:any) {
    return value
}

function human2file(value:any) {
    return value
}
//------------------------------------------------------------------------------
function image2human(value:any) {
    if (!value) {
        value                   = ''
    }
    return `<img src='${value}'>`
}

function human2image(value:any) {
    return value || null
}
//------------------------------------------------------------------------------
const integerTest               = RegExp('^[-+]?[0-9]*$')

function integer2human(value:any) {
    if (!value)                 { return '0' }
    return parseInt(value).toString()
}

function human2integer(value:any) {
    if (value === null)         { return 0 }
    let bits                    = integerTest.exec(value)
    if (!bits)                  { return undefined }
    return +bits[0]
}

function integer2criteria(value:any) {
// console.log('[integer2criteria]', value)
    return value === null || value == '!' || value == '-' ? value : integer2human(value)
}

function criteria2integer(value:any) {
// console.log('[criteria2integer]', value)
    if (!value || value == '!' || value == '-') {
        return value || null
    } else {
        return human2integer(value) || null
    }
}

function integer2none(value:any) {
    if (!value)                 { return '' }
    return parseInt(value).toString()
}
//------------------------------------------------------------------------------
function percent2human(value:any) {
    return decimal(value, '.', 2)
//     if (!value)                 { return '0.00' }
//     value                       = parseInt(value)
//     let sign                    = value < 0 ? '-' : ''
//     value                       = Math.abs(value)
//     let int                     = Math.floor(value / 100)
//     let dec                     = value % 100
//     return sign + int + '.' + num2(dec)
}
//------------------------------------------------------------------------------
const quantityTest              = RegExp(
      '^'
    + '([-+])?'                 // sign
    + '([0-9]+)?'               // integer
    + '[.,]?'
    + '([0-9]{1,3})?'           // decimal
    + '$'
    )

function quantity2human(value:any) {
    return decimal(value, '.', 3).replace(/0+$/,'').replace(/\.$/,'')
}

function human2quantity(value:any) {
    if (value === null)         { return 0 }
    let bits                    = quantityTest.exec(value)
    if (!bits)                  { return undefined }
    if (bits[2] && bits[2].length > 13)
                                { return undefined }
    let sign                    = bits[1] == '-' ? -1 : 1
    let int                     = bits[2] ? +bits[2] : 0
    let dec                     = bits[3] ? +bits[3] : 0
    if (bits[3]) {
        if      (bits[3].length < 2) { dec *= 100 }
        else if (bits[3].length < 3) { dec *=  10 }
    }
    return sign * (int * 1000 + dec)
}

function quantity2criteria(value:any) {
// console.log('[quantity2criteria]', value)
    return value == '!' || value == '-' ? value : quantity2human(value)
}

function criteria2quantity(value:any) {
// console.log('[criteria2quantity]', value)
    if (!value || value == '!' || value == '-') {
        return value || null
    } else {
        return human2quantity(value) || null
    }
}

//------------------------------------------------------------------------------
function text2human(value:any) {
    try             { return value.replace(/\\n/g, '\n').replace(/~~/g, '  ')  }
    catch(err:any)  { return value }
}
//------------------------------------------------------------------------------
function textarea2human(value:any) {
    try             { return value.replace(/~~/g,'\n')  }
    catch(err:any)  { return value }
}
//------------------------------------------------------------------------------
const timeTest                  = RegExp(
      '^'
    + '([01][0-9]|2[0-3])'      // hours
    + ':?'
    + '([0-5][0-9])?'           // minutes
    + '$'
    )

function time2human(value:any) {
    return format_date(value, '%H:%M')
}

function human2time(value:any) {
    let bits                    = timeTest.exec(value)
    if (!bits)                  { return undefined }
    let tH                      = bits[1] ? +bits[1] : 0
    let tM                      = bits[2] ? +bits[2] : 0
    let res                     = new Date(`0000-01-01 ${num2(tH)}:${num2(tM)}`)
    if (isNaN(res.valueOf()))   { return undefined }
    else                        { return res }
}
//------------------------------------------------------------------------------
const timestampTest             = RegExp(
      '^'
    + '([0-2]?[0-9]|3[01])'     // day
    + '([./-](0?[0-9]|1[012])'  // month
      + '([./-]([0-9]{4}))?'    // year
    + ')?'
    + '( ([01]?[0-9]|2[0-3])'   // hours
      + '(:([0-5]?[0-9])'       // minutes
        + '(:([0-5]?[0-9])'     // seconds
          + '(\\.([0-9]{1,3}))?'// milliseconds
        + ')?'
      + ')?'
    + ')?'
    + '$'
    )

function timestamp2human(value:any) {
    return value === null ? '00.00.0000 00:00:00.000' : format_date(value, '%d.%m.%Y %H:%M:%S.%i')
}

function human2timestamp(value:any) {
// console.log(`[human2timestamp] (${value}:${is.type(value)})`)
    if (value == '00.00.0000 00:00:00.000') {
        return null
    }
    let bits                    = timestampTest.exec(value)
    if (!bits)                  { return undefined }
    let now                     = new Date()
    let tz                      = now.getTimezoneOffset()
    let dD                      = bits[ 1] ? +bits[ 1]: now.getDate    ()
    let dM                      = bits[ 3] ? +bits[ 3]: now.getMonth   ()   + 1
    let dY                      = bits[ 5] ? +bits[ 5]: dM < now.getMonth() + 2
                                                      ? now.getFullYear()
                                                      : now.getFullYear()   - 1
    let tH                      = bits[ 7] ? +bits[ 7]: 0
    let tM                      = bits[ 9] ? +bits[ 9]: 0
    let tS                      = bits[11] ? +bits[11]: 0
    let tD                      = bits[13] ? +bits[13]: 0
    if (bits[13]) {
        if      (bits[13].length < 2) { tD *= 100 }
        else if (bits[13].length < 3) { tD *=  10 }
    }
    // no idea why we have to allow for the timezone in 'human2timestamp'
    // but not in 'human2datetime'
    let res                     = new Date(dY, dM - 1, dD, tH, tM-tz, tS, tD)
// console.log(`[human2timestamp] res:${res}:${is.type(res)}`)
    if (isNaN(res.valueOf()))   { return undefined }
    else                        { return res }
}
//------------------------------------------------------------------------------
//------------------------------------------------------------------------------
export const Format             =
{ format_date
, thousands
, boolean2flag
, cod2human         , human2cod
, code2human        , human2code
, date2human        , human2date
, datetime2human    , human2datetime
, decimal2human     , human2decimal     , decimal2criteria  , criteria2decimal
, exchrate2human    , human2exchrate
, image2human       , human2image
, integer2human     , human2integer     , integer2criteria  , criteria2integer
, integer2none
, percent2human
, quantity2human    , human2quantity    , quantity2criteria , criteria2quantity
, text2human
, textarea2human
, time2human        , human2time
, timestamp2human   , human2timestamp
}
//------------------------------------------------------------------------------
//------------------------------------------------------------------------------
export const Type               =
{ bare                          : { cls:'bare'  , format:text2human     }
, boolean                       : { cls:'center', format:text2human     }
, btn                           : { cls: false  , format:text2human     }
, checkbox                      : { cls:'center', format:text2human     }
, cod                           : { cls: false  , format:text2human     }
, code                          : { cls: false  , format:code2human     }
, date                          : { cls:'center', format:date2human     }
, dates                         : { cls:'center', format:date2human     }
, datetime                      : { cls:'center', format:datetime2human }
, decimal                       : { cls:'right' , format:decimal2human  }
, decnone                       : { cls:'right' , format:decimal2none   }
// , editor                        : { cls: false  , format:text2human     }
, exchrate                      : { cls:'right' , format:exchrate2human }
, file                          : { cls:false   , format:file2human     }
, flag                          : { cls:'center', format:boolean2flag   }
, image                         : { cls:'false' , format:image2human    }
, integer                       : { cls:'right' , format:integer2human  }
, intnone                       : { cls:'right' , format:integer2none   }
, percent                       : { cls:'right' , format:percent2human  }
, quantity                      : { cls:'right' , format:quantity2human }
, text                          : { cls: false  , format:text2human     }
, textarea                      : { cls:'lines' , format:textarea2human }
, timestamp                     : { cls:'center', format:timestamp2human}

, hide                          : { cls:'bare'  , format:text2human     }
, center                        : { cls:'center', format:text2human     }
, centre                        : { cls:'center', format:text2human     }
, right                         : { cls:'right' , format:text2human     }
}
//------------------------------------------------------------------------------
// convert column size (1-12) to '<name> columns'
const skeletonWidths            =
    'zero,one,two,three,four,five,six,seven,eight,nine,ten,eleven,twelve'.split(/,/g)

export function columnClass(width:number) {
    return skeletonWidths[width] + ' columns'
}
//------------------------------------------------------------------------------
//------------------------------------------------------------------------------
function dropTargetExtension(opts) {
    let defaults                = { callback:null, max_files:0, max_size:0 }
    opts                        = Object.assign(defaults, opts)
// console.log('[dropTarget] this:', this)
    return this.each(() => {
// console.log('[dropTarget] each:', this)
        let $target             = jQuery(this)
        $target.bind('dragover' , (event:any) => {
            $target.addClass('dragover')
            return event_done(event)
        })
        $target.bind('dragleave', (event:any) => {
            $target.removeClass('dragover')
            return event_done(event)
        })
        $target.bind('drop'     , (event:any) => {
// console.log('[dropTarget] drop:', event)
            $target.removeClass('dragover')
                 .addClass('dropped')
            // get all files that are dropped
            let files               = event.originalEvent.target.files || event.originalEvent.dataTransfer.files
            // convert uploaded file to data URL and pass to callback
// console.log('[dropTarget] files:', files)
            if (opts.callback) {
                for (let i = 0; i < (opts.max_files || files.length); i++) {
                    let fr      = new FileReader()
                    fr.onload   = (event:any) => {
// console.log('[dropTarget] FileReader.onload:', event)
                        opts.callback(files[i], event.target.result)
                    }
                    fr.readAsDataURL(files[i])
                }
            }
            $target.removeClass('dropped')
            return event_done(event)
        })
    })
    //------------------------------------------------------------------------------
    function event_done(event:any) {
        event.preventDefault()
        event.stopPropagation()
        return false
    }
}
//------------------------------------------------------------------------------
export const dropTarget         =
    { setup: () => jQuery.fn.extend({ dropTarget:dropTargetExtension }) }
//------------------------------------------------------------------------------
