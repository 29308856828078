//------------------------------------------------------------------------------
declare const RESOLVE, REJECT
//------------------------------------------------------------------------------
import  { Component                                 } from '@angular/core'
import  { ComponentFactoryResolver                  } from '@angular/core'
import  { ComponentRef                              } from '@angular/core'
import  { ViewChild                                 } from '@angular/core'
//------------------------------------------------------------------------------
import  { ModalElem, BaseComp, ModalDiv             } from './service'
//------------------------------------------------------------------------------
@Component({selector:'ModalTag', template:ModalDiv})
export class ModalTag extends BaseComp {
    //--------------------------------------------------------------------------
    constructor( private cfr:ComponentFactoryResolver ) {
        super()
        this.glob.registerRoot(this)
    }
    comp_type                   = 'modal'
    //--------------------------------------------------------------------------
    @ViewChild(ModalElem, {static:false})
    element             :ModalElem
    compRef             :ComponentRef<Component>
    //--------------------------------------------------------------------------
    clearTag() {
        try {
            if (this.compRef) {
                this.compRef.destroy()
            }
            this.compRef        = null
        } catch(err) {
            this.log('clearTag:', err)
        }
    }
    //--------------------------------------------------------------------------
    loadTagP(opts:any={}) {
this.log('loadTagP opts:', opts)
        if (!opts.compname)     { return REJECT(this.clearTag()) }
        let comp                = this.glob.components[opts.compname]
        if (comp === undefined) {
            this.log(`********** component "${opts.compname}" not found`)
            return REJECT()
        }
        try {
            this.clearTag()
            let container       = this.element.viewContainerRef
            container.clear()
            this.compRef        = container.createComponent(this.cfr.resolveComponentFactory(comp))
            let instance        = <ModalTag>this.compRef.instance
            return instance.onLoadInstanceP(opts, false)
            .then ((res) => { this.log('ModalTag.resolved'); return RESOLVE(res) })
            .catch((err) => { this.log('ModalTag.rejected'); return REJECT (err) })
        } catch(err) {
            this.log('********** loadTagP err:', err)
            return REJECT()
        }
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
