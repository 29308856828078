//------------------------------------------------------------------------------
declare const RESOLVE, REJECT
//
//------------------------------------------------------------------------------
import  { Component                                 } from '@angular/core'
import  { ListDiv               , ListComp
        , PckDiv                , TabbedComp
        , PckService                                } from './service'
//------------------------------------------------------------------------------
@Component({selector:'PckLst', template:ListDiv})
export class PckLst extends ListComp {
    //--------------------------------------------------------------------------
    constructor( public service:PckService ) { super() }
    comp_type                   = 'main'
    //--------------------------------------------------------------------------
    listDefn            :any    =
        { date          : {lab:''                   , wide: 4, typ:'dates'}
        , typ_name      : {lab:'type'               , wide: 5}
        , number        : {lab:'number'             , wide: 5}
        , ext_number    : {lab:'reference'          , wide: 6}
        , knt_name      : {lab:'account'            , wide:12}
        , lnd_name      : {lab:'country'            , wide: 4}
        , wrk_name      : {lab:'workflow'           , wide: 8}
        , stt_name      : {lab:'status'             , wide: 8}
        , netto         : {lab:'netto'              , wide: 4, typ:'decimal', tot:true}
        , brutto        : {lab:'brutto'             , wide: 4, typ:'decimal', tot:true}
        , cur_typ       : {lab:'cur'                , wide: 2}
        , wsale_yn      : {lab:'w/s'                , wide: 1, typ:'flag'}
        , booked        : {lab:'bkd'                , wide: 1, typ:'flag'}
        , events_flag   : {lab:'evn'                , wide: 1, typ:'flag'}
        }
    //--------------------------------------------------------------------------
    onLoad_task                 = 'load'
    //--------------------------------------------------------------------------
    onInitP() {
        this.dft                =
            { channel           : this.ctx.channel
            , rsr_id            : this.ctx.rsr_id
            , status            : this.ctx.status
            , vendor            : this.ctx.vendor
            , vorgang           : this.ctx.vorgang
            , workflow          : this.ctx.workflow
            }
        this.defineList         (this.listDefn      )
        this.setHeader          ('=menu='           )
        this.buttonSelAll       (this.onSelectAllP  )
        this.buttonInvSel       (this.onInvertSelP  )
        this.buttonPrint        (this.onPrintP      )

        this.makeButton         (this.onWarehouseP  , 'change warehouse', '', 'tr')

        this.eventButtons      (this.ctx.events    )
        this.onCriteria         (this.onCriteriaP   )
        this.listClick          (this.onSelectItmP  )
        this.listDclick         (this.onUpdateP     )

        return this.loadAndFocusP()
    }
    //--------------------------------------------------------------------------
    defaultCriteria() {
        let since               = this.ctx.since    ? this.ctx.since
                                : this.ctx.status   ? 'month'
                                : this.ctx.vorgang  ? 'week'
                                                    : 'day'
        return { date0:this.getDateSince(since) }
    }
    //--------------------------------------------------------------------------
    onEventP(key:string, values:any) {
        return this.getSelectedIdsP()
        .then((ids  ) => this.busyServiceEmitP('event', { event:key, vrg_ids:ids }) )
        .then((     ) => this.loadAndFocusP() )
    }
    //--------------------------------------------------------------------------
    onUpdateCompName            = 'Pck'
//     onUpdateP(idx:number) {
//         let ctx                 = this.onUpdateContext(idx)
//         return this.callComponent('Pck', ctx)
//         .then((res:any) => this.onUpdateDoneP(idx, res) )
//     }
    onUpdateContext(idx:number) {
        let item                = this.list[idx]
        let header              = (!item.knt_name)  ? item.number
                                                    : `${item.number}: ${item.knt_name}`
        return  { header        : header
                , id            : item.id
                }
    }
    //--------------------------------------------------------------------------
    onPrintP(key:string, values:any) {
        return this.getSelectedIdsP()
        .then((ids:any) => {
            this.messageText('print request submitted')
            return this.downloadP(
                { vrg_ids       : ids
                , TIMEOUT       : ids.length * 2
                },
                { name          : 'print'
                , task          : 'print'
                }
              )
        })
    }
    //--------------------------------------------------------------------------
    async onWarehouseP(key:string, values:any) {
        let item                = await this.getSelectedItemP()
        let res                 = await this.callComponent('ParamsModal',
            { header            : 'select new warehouse...'
            , itemDefn          :
                { rsr_id        : {lab:'warehouse', cod:'rsr'} }
            , values            :
                { rsr_id        : item.rsr_id }
            }
        )
        if (!res)               { return }
        if (res.rsr_id == item.rsr_id) {
            this.messageError('selected warehouse same as original')
            return this.setFocusP()
        }
        await this.busyServiceEmitP('event',
            { event             : 'rsr'
            , vrg_ids           : [ item.id ]
            , data              :
                { rsr_id_old    : item.rsr_id
                , rsr_id_new    : res.rsr_id
                }
            }
        )
//         return this.onBackP(false)
        this.messageDone('warehouse change scheduled')
        return this.setFocusP()
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
@Component({selector:'Pck', template:PckDiv})
export class Pck extends TabbedComp {
    //--------------------------------------------------------------------------
    constructor( public service:PckService ) { super() }
    //--------------------------------------------------------------------------
    onInitP() {
        this.service.initTabs(this.ctx.id, 'dtl')
        this.ctx.vrg_id         =
        this.dft.vrg_id         = this.ctx.id
        this.stab               = this.stab || 'pst'
        this.cache              = {}
        return RESOLVE()
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
