<form ref-form='ngForm'
  (ngSubmit)='form.valid && onCriteria(form.value)'
  (keydown.meta.arrowleft) ='glob.onPrev(form)' (keydown.meta.bracketleft) ='glob.onPrev(form)'
  (keydown.meta.arrowright)='glob.onNext(form)' (keydown.meta.bracketright)='glob.onNext(form)'
>
  <div class='pagehead'>
    <div class='hidden-submit'><input type='submit' tabindex='-1'/></div>
    <span                   class='actions top floatr {{header && "header"}}'>
      <button *ngIf ='lD.onDump && !isMixed'
                            class='btn'
                            (click)='lD.onDump()'>dump</button>
      <button *ngFor='let btn of btns.tl'
                            class='{{btn.cls}} {{btn.offClass}} {{btn.hide}}'
                            (click)='btn.CLICK(form, $event)'>{{btn.lab}}</button>
      <button *ngFor='let btn of btns.tr'
                            class='{{btn.cls}} {{btn.offClass}} {{btn.hide}}'
                            (click)='btn.CLICK(form, $event)'>{{btn.lab}}</button>
    </span>
    <h3       *ngIf ='btns.bk.length || header'>
      <button *ngFor='let btn of btns.bk'
                            class='btn back {{btn.offClass}} {{btn.hide}}'
                            (click)='btn.CLICK(form, $event)'>{{glob.backArrow}}</button>
      <span   *ngIf ='header'>{{header}}</span>
    </h3>
    <span     *ngIf ='!message.text'
                            class='message'>items: {{list.length}}</span>
    <span                   class='message {{message.cls}}'>{{message.text}}&nbsp;</span>
    <span class='u-cf'></span>
  </div>
  <div class='list container pagebody'>
    <table class='list'>
      <thead>
        <tr   *ngIf ='labelsOnly'>
          <th *ngFor='let key of lD.KEYS' class='{{lD[key].divCls}}'>
            <label>{{lD[key].lab}}</label>
          </th>
        </tr>
        <tr *ngIf ='!labelsOnly'
                            class='critLabels'>
          <th *ngFor='let key of lD.KEYS'
                            class='{{lD[key].divCls}}'>
            <DateLC *ngIf='lD[key].typ=="dates"'    [def]='lD[key]' [datum]='criteria.date0' name='date0' ngModel></DateLC>
            <label  *ngIf='lD[key].typ!="dates"'>{{lD[key].lab}}</label>
          </th>
        </tr>
        <tr *ngIf ='!labelsOnly'>
          <th *ngFor='let key of lD.KEYS'
                        [ngSwitch]   ='lD[key].typ'>
            <div        *ngSwitchCase='"bare"'      ></div>
            <BooleanLC  *ngSwitchCase='"check"'     [def]='lD[key]' [datum]='criteria[key]' [name]='key'  ngModel></BooleanLC>
            <DateLC     *ngSwitchCase='"date"'      [def]='lD[key]' [datum]='criteria[key]' [name]='key'  ngModel></DateLC>
            <DateLC     *ngSwitchCase='"dates"'     [def]='lD[key]' [datum]='criteria.date1' name ='date1'ngModel></DateLC>
            <DatetimeLC *ngSwitchCase='"datetime"'  [def]='lD[key]' [datum]='criteria[key]' [name]='key'  ngModel></DatetimeLC>
            <DecimalLC  *ngSwitchCase='"decimal"'   [def]='lD[key]' [datum]='criteria[key]' [name]='key'  ngModel></DecimalLC>
            <DecimalLC  *ngSwitchCase='"decnone"'   [def]='lD[key]' [datum]='criteria[key]' [name]='key'  ngModel></DecimalLC>
            <ExchrateLC *ngSwitchCase='"exchrate"'  [def]='lD[key]' [datum]='criteria[key]' [name]='key'  ngModel></ExchrateLC>
            <ImageLC    *ngSwitchCase='"image"'     [def]='lD[key]' [datum]='criteria[key]' [name]='key'  ngModel></ImageLC>
            <IntegerLC  *ngSwitchCase='"integer"'   [def]='lD[key]' [datum]='criteria[key]' [name]='key'  ngModel></IntegerLC>
            <IntegerLC  *ngSwitchCase='"intnone"'   [def]='lD[key]' [datum]='criteria[key]' [name]='key'  ngModel></IntegerLC>
            <NoneLC     *ngSwitchCase='"none"'      [def]='lD[key]' [datum]='criteria[key]' [name]='key'  ngModel></NoneLC>
            <DecimalLC  *ngSwitchCase='"percent"'   [def]='lD[key]' [datum]='criteria[key]' [name]='key'  ngModel></DecimalLC>
            <QuantityLC *ngSwitchCase='"quantity"'  [def]='lD[key]' [datum]='criteria[key]' [name]='key'  ngModel></QuantityLC>
            <TextLC     *ngSwitchDefault            [def]='lD[key]' [datum]='criteria[key]' [name]='key'  ngModel></TextLC>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr   *ngFor='let item of list; let idx = index'
                            class='{{item.SELECT && "SELECT" || ""}} {{item.HIDDEN && "HIDDEN" || ""}} {{item.GROUP}} {{item.CLASS}}'
        >
          <td *ngFor='let key of lD.KEYS'
                            class='{{lD[key].cls}}'
                            (click)='lCLICK(idx, key, $event)'>
            <img *ngIf='lD[key].typ=="image"; else notAnImage' src='{{item[key]}}' alt='no image'>
            <ng-template #notAnImage>
              {{ item.PREFORM ? item.PREFORM[key] : lD[key].format(item[key]) }}
              <span *ngFor= 'let cellBTN of lD[key].cellBTNs'><button
                *ngIf     = 'cellBTN.when(item)'
                (click)   = 'cellBTN.click(idx)'
                [disabled]= 'cellBTN.off'
                class     = '{{cellBTN.cls}}'
                >{{cellBTN.lab}}</button>
              </span>
            </ng-template>
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr *ngIf ='lD.TOTKEYS.length > 0'>
          <th *ngFor='let key of lD.KEYS'
                            class='{{lD[key].divCls}}'>
            <span *ngIf ='lD.TOTS[key]'>{{lD[key].format(lD.TOTS[key])}}</span>
          </th>
        </tr>
        <tr   *ngIf ='list.length > 20'>
          <th *ngFor='let key of lD.KEYS'
                            class='{{lD[key].divCls}}'>
            <label>{{lD[key].lab}}</label>
          </th>
        </tr>
      </tfoot>
    </table>
  </div>
  <div class='pagefoot'>
    <span                   class='actions bot floatl'>
      <button *ngFor='let btn of btns.bl'
                            class='{{btn.cls}} {{btn.offClass}} {{btn.hide}}'
                            (click)='btn.CLICK(form, $event)'>{{btn.lab}}</button>
    </span>
    <span                   class='actions bot floatr'>
      <button *ngFor='let btn of btns.br'
                            class='{{btn.cls}} {{btn.offClass}} {{btn.hide}}'
                            (click)='btn.CLICK(form, $event)'>{{btn.lab}}</button>
      <input *ngFor='let btn of btns.ok'
                            class='{{btn.cls}} {{btn.offClass}} {{btn.hide}}'
                            type='submit' value='{{btn.lab}}'>
    </span>
    <input    *ngFor='let key of lD.HIDE' type='hidden' [ngModel]='criteria[key]' [name]='key'>
    <input class='key_catcher u-cf'>
  </div>
</form>
<div *ngIf='debug'>criteria:{{criteria|json}}, off:{{isOff}}</div>
