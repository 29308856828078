//------------------------------------------------------------------------------
declare const window, RESOLVE, REJECT, NOOP, jQuery, is, regexTest
//------------------------------------------------------------------------------
// actual 'global' functions...
const global                    = window
const log               :any    = console.log
//------------------------------------------------------------------------------
const isTest                    = (obj, rgx) => rgx.test(is.type(obj))
const isDeep                    = (obj, rgx) => rgx.test(is.deep(obj))
global.is                       =
{ 'type'                        : (obj) => {
    try        { return obj.constructor.name }
    catch(err) { return Object.prototype.toString.call(obj).slice(8,-1) }
  }
, 'deep'                        : (obj) => {
    // check in the reverse order to 'test'
    let name
    try {
        name                    = Object.prototype.toString.call(obj).slice(8,-1)
        if (name == 'Object') {
            name                = obj.constructor.name
        }
        return name
    } catch(e) {
        return undefined
    }
  }
, 'anonymous'                   : (obj) => isTest(obj, /^anonymous$/            )
, 'array'                       : (obj) => isTest(obj, /^Array$/                )
, 'bigobject'                   : (obj) => isTest(obj, /^BigObject$/            )
, 'boolean'                     : (obj) => isTest(obj, /^Boolean$/              )
, 'buffer'                      : (obj) => isTest(obj, /^(Buffer|UintArray)$/   )
, 'date'                        : (obj) => isTest(obj, /^(Date|Moment)$/        )
, 'email'                       : (obj) => /^[^\s@]+@([^\s@]+\.)+[^\s@]+$/.test(obj)
, 'error'                       : (obj) => isDeep(obj, /^Error$/                )
, 'function'                    : (obj) => isTest(obj, /^(Async)?Function$/     )
, 'integer'                     : (obj) => isTest(obj, /^Number$/) && Math.trunc(obj) == obj
, 'taggedlogger'                : (obj) => isTest(obj, /^TaggedLogger$/         )
, 'moment'                      : (obj) => isTest(obj, /^Moment$/               )
, 'null'                        : (obj) => isTest(obj, /^Null$/                 )
, 'number'                      : (obj) => isTest(obj, /^Number$/               )
, 'object'                      : (obj) => isTest(obj, /^Object$/               )
, 'promise'                     : (obj) => isTest(obj, /^Promise$/              )
, 'regexp'                      : (obj) => isTest(obj, /^RegExp$/               )
, 'self'                        : (obj) => isTest(obj, /^Self$/                 )
, 'string'                      : (obj) => isTest(obj, /^String$/               )
, 'undefined'                   : (obj) => isTest(obj, /^Undefined$/            )
, 'empty'                       : (obj) => {
    // a boolean is never empty...
    if (is.boolean(obj))                                        { return false }
    // null, number, string, undefined
    if (!obj)                                                   { return true  }
    // array, buffer
    if ((is.array(obj) || is.buffer(obj)) && obj.length < 1)    { return true  }
    // date, moment
    if ((is.date(obj) || is.moment(obj)) && !obj.valueOf())     { return true  }
    // object
    if (is.object(obj) && Object.keys(obj).length < 1)          { return true  }
    // everything else
    return false
  }
}
//------------------------------------------------------------------------------
global.NOOP                     = () => {}
//------------------------------------------------------------------------------
global.regexTest                = (item, ...values) => {
    if (values.length == 1 && is.array(values[0])) {
        values                  = values[0]
    }
    return RegExp(`^(${values.join('|')})$`, 'i').test(item)
}
//------------------------------------------------------------------------------
global.promiseLoop              = (items, result, func) => {
    if (!is.function(func)) {
        return REJECT('third argument passed to promiseLoop must be "function(item, idx, result)"')
    }
    result                      = result || []
    if (is.array(items)) {
        // good to go...
    } else if (is.number(items) && items > -1) {
        items                   = Array(items).fill(0).map((v, i) => i)
    } else if (is.object(items)) {
        items                   = [ items ]
    } else {
        return RESOLVE(result)
    }
    return new Promise((resolve, reject) => {
        function loop(idx) {
            if (idx >= items.length) {
                return resolve(result)
            }
            try {
                func(items[idx], idx, result)
                .then(() => setTimeoutZero(loop, idx + 1) )
                .catch((err) => {
                    log('promiseLoop rejection:', err)
                    reject(err)
                })
            } catch(err) {
                log('promiseLoop try/caught:', err)
                reject(err)
            }
        }
        loop(0)
    })
}

//------------------------------------------------------------------------------
const  setTimeoutZero           =
global.setTimeoutZero           = (func, ...args) => setTimeout(func, 0, ...args)

global.delayTimer               = (msec=10) =>
    new Promise((resolve) => setTimeout(() => resolve(null), msec) )
//------------------------------------------------------------------------------
//------------------------------------------------------------------------------
global.RESOLVE      = (res              ) => Promise.resolve(res )
global.REJECT       = (...args          ) => Promise.reject(formatMsg(...args))
global.REJECTDUP    = (err, ...args     ) => err == 'DUP' ? REJECT(...args) : REJECT(err)
global.SUCCESS                  = (   ) => Promise.resolve('ok')
global.LOG_RESOLVE  = (res, msg='result') => RESOLVE(log(`RESOLVE ${msg}:`, res) || res)
global.LOG_REJECT   = (err, msg='error' ) => REJECT (log(`REJECT ${msg}:` , err) || err)
//------------------------------------------------------------------------------
global.getStack                 = function() {
    return (new Error()).stack.toString().split(/\n +at +/).slice(1)
}
//------------------------------------------------------------------------------
export function stringify(obj, depth=0, done=null) {
    depth                       = depth || 0
    done                        = done  || new Set()
    if (is.bigobject(obj))      { return '<BigObject>'  }
    if (is.function (obj))      { return '<Function>'   }
    if (is.null     (obj))      { return 'null'         }
    if (is.undefined(obj))      { return 'undefined'    }
    if (is.string   (obj))      { return fixString(obj)   }
    if (is.date     (obj))      { return obj.toJSON()     }
    if (is.boolean  (obj))      { return obj.toString()   }
    if (is.number   (obj))      { return obj.toString()   }
    if (is.buffer   (obj))      { return obj.toString()   }
    if (is.error    (obj))      { return obj.stack || obj.toString() }
    if (is.moment   (obj))      { return obj.format('YYYY-MM-DD HH:mm:ss')}
    if (is.regexp   (obj))      { return obj.toString()   }
    if (is.array    (obj)) {
        if (done.has(obj))      { return '<repeat reference>' }
        done.add(obj)
        if (depth > 20)         { return '<array too deep>' }
        let arr                 = obj.map((value) => stringify(value, depth + 1, done))
        return joinOutput(arr, '[', ']')
    }
    try {
        if (done.has(obj))      { return '<repeat reference>' }
        done.add(obj)
        if (depth > 20)         { return '<object too deep>' }
        let arr                 = []
        for (let key in obj) {
            arr.push(key + ': ' + stringify(obj[key], depth + 1, done))
        }
        return joinOutput(arr, '{', '}')
    } catch(err) {
        try      { return obj.toString() }
        catch(e) { return obj         }
    }
    function fixString(str) {
        return '\'' + str.replace(/'/g, '\\\''
        ).replace(/\r\n?|\n\r?/g, '\\n\' +\n' + '  '.repeat(depth + 1) + '\''
        ) + '\''
    }
    function joinOutput(arr, head, tail) {
        if (arr.length < 1)     { return `${head} ${tail}` }
        let tmp                 = `${head} ` + arr.join(', ') + ` ${tail}`
        if (tmp.length < 150)   { return tmp }
        tmp                     = '\n' + '  '.repeat(depth)
        return `${tmp}${head} ` + arr.join(`${tmp}, `) + `${tmp}${tail}`
    }
}
//------------------------------------------------------------------------------
global.parseJSON                = (obj) =>
      is.object(obj)            ? obj
    : is.string(obj) && obj != ''
                                ? JSON.parse(obj.replace(/'/g,'"')
                                      .replace(/([:[])\s*'(.+?)'/g,'$1"$2"')
                                      .replace(/([{,])\s*(\w+?)\s*:/g,'$1"$2":')
                                      )
                                : {}
//------------------------------------------------------------------------------
export function clone(a:any, b:any={}, c:any={}, d:any={}) {
    if (is.array(a)) {
        return Object.assign([], fix(a), fix(b), fix(c), fix(d))
    } else {
        return Object.assign({}, fix(a), fix(b), fix(c), fix(d))
    }
    function fix(obj:any) {
        if (is.object(obj)) {
            let res             = {}
            for (let key in obj) {
                res[key]        = fix(obj[key])
            }
            return res
        } else if (is.array(obj)) {
            return obj.map((item:any) => fix(item))
        } else {
            return obj
        }
    }
}
//------------------------------------------------------------------------------
const toDate                    = (date:any=null) => is.date(date) ? date : new Date()
export const getDate            =
{ today         : (date:any=null) => { let d = toDate(date); return new Date(d.getFullYear()    , d.getMonth()    , d.getDate()    ) }
, prevWeek      : (date:any=null) => { let d = toDate(date); return new Date(d.getFullYear()    , d.getMonth()    , d.getDate() - 6) }
, prevFortnight : (date:any=null) => { let d = toDate(date); return new Date(d.getFullYear()    , d.getMonth()    , d.getDate() -13) }
, prevMonth     : (date:any=null) => { let d = toDate(date); return new Date(d.getFullYear()    , d.getMonth() - 1, d.getDate()    ) }
, prevYear      : (date:any=null) => { let d = toDate(date); return new Date(d.getFullYear() - 1, d.getMonth()    , d.getDate()    ) }
, since         : (time:any=null) => {
    switch (time) {
      case 'd': case 'day'      : return getDate.today          ()
      case 'w': case 'week'     : return getDate.prevWeek       ()
      case 'f': case 'fortnight': return getDate.prevFortnight  ()
      case 'm': case 'month'    : return getDate.prevMonth      ()
      case 'y': case 'year'     : return getDate.prevYear       ()
      case 'n': case 'none'     : return new Date('2020-01-01')
      default                   : return getDate.today          ()
    }
  }
}
//------------------------------------------------------------------------------
let   onResizeTimer
const onResize                  = (event=null, loops=1) => {
    // only one onResize timeout at a time...
    if (!onResizeTimer) {
        onResizeTimer           = setTimeout(calcResize, 100, loops)
    }
}

const calcResizeLog             = (...args) => {
    console.log('[calcResize] ' + args.join(' '))
}
function calcResize(loops) {
    onResizeTimer               = clearTimeout(onResizeTimer)
    let $pagebody               = jQuery('MainTag .pagebody').last()
    if ($pagebody.length < 1) {
        calcResizeLog('no pagebody (yet)')
        // loop until a pagebody is present...
        return //onResize(null, 1)
    }
    let bodyH               = jQuery('body').outerHeight()
                            + 14    // pagebody margin?
    let windH               = jQuery(window).height()
//     calcResizeLog(`loops:${loops}, body:${bodyH}, window:${windH}`)
    if (bodyH != windH) {
        let height          = windH - bodyH + $pagebody.outerHeight()
        // minimum pagebody height of 400...
        if (height > 400) {
            $pagebody.outerHeight(height)
            onResize(null, 1)
        } else {
            $pagebody.outerHeight(400)
        }
    } else if (loops < 2) {
        onResize(null, loops + 1)
    }
}
//==============================================================================
import { Config                                     } from '../config'
import { MySocket                                   } from './socket'
import { dropTarget, Format                         } from './fields'
//------------------------------------------------------------------------------
global.Format                   = Format
const  formatMsg                =
global.formatMsg                = (...vals) => vals.length > 1 ? vals.join(', ') : vals[0]
//------------------------------------------------------------------------------
class Global{
    name                :string = this.constructor.name
    config              :Config
    socket              :MySocket
    session             :any    = {}
//     backArrow           :string = '\u2190'
    backArrow           :string = '\u21D0'
    onResize            :any    = onResize
    isDevel             :boolean= false
    //--------------------------------------------------------------------------
    windowClass         :string = ''
    start_page          :string = 'Logo'
    console_log         :any    = console.log
    //--------------------------------------------------------------------------
    constructor() {
        this.config             = new Config()
        this.socket             = new MySocket(this.config)
        this.name               = window.location.hostname
        if (/skaldev.com$/.test(this.name)) {
            // *.skaldev.com
            this.isDevel        = true
            this.showDev        = true
            window.document.title
                                = '[ SKALDEV ]'
        } else if (/^(192\.168\.[\.\d]+|10\.[\d\.]|localhost)$/i.test(this.name)) {
            this.isDevel        = true
            this.showDev        = true
            window.document.title
                                = `[ ${this.name.toUpperCase()} ]`
        } else {
            this.isDevel        = false
            this.showDev        = false
        }
        dropTarget.setup()
//         window.addEventListener('beforeunload', this.beforeUnload() )
        window.addEventListener('resize'      , this.onResize       )
        // schedule a resize...
        setTimeout(this.onResize, 500)
        this.loadCriteriaCache()
    }
    //--------------------------------------------------------------------------
    components          :any    = {}
    // modal classes
    modalClasses        :any    = []
    registerComponents(comps:any) {
        comps.map((comp:any) => {
            this.components[comp.name]
                                = comp
        })
    }
    compIsModal(comp:any) {
        return this.modalClasses.some(
            (modalClass:any) => modalClass.isPrototypeOf(comp)
        )
    }
    //--------------------------------------------------------------------------
//     private beforeUnload() {
//         return (event:any) => {
//             let name            = this.top.name
//             this.log('[beforeUnload]', name)
//             if (this.isDevel || regexTest(name, 'null', 'Logo', 'Login')) {
//                 // we can just leave...
//                 return true
//             }
//             event.preventDefault()
//             return false
//         }
//     }
    //--------------------------------------------------------------------------
    criteriaCache       :any    = {}

    private lsParse(key) {
        try      { return JSON.parse(localStorage.getItem(key)) }
        catch(e) { return localStorage.removeItem(key)          }
    }
    private lsStore(key, value) {
        localStorage.setItem(key, JSON.stringify(value))
    }
    private loadCriteriaCache() {
        let keys, storeKey, cacheKey, data
        let min_age             = new Date().valueOf() - 43200000 // 12 hours
        for (let x = 0; x < localStorage.length; x++) {
            // criteria keys are 'criteria:<menu_id>:<component_name>'...
            keys                = /^criteria:(\d+:\w+)$/.exec(localStorage.key(x))
            if (!keys) {
                // not a cached criteria item...
                return
            }
            storeKey            = keys[0]
            data                = this.lsParse(storeKey)
            if (is.object(data) && data?.created > min_age) {
                cacheKey        = keys[1]
                this.criteriaCache[cacheKey]
                                = data.criteria || {}
                return
            }
            // remove rubbish data...
            localStorage.removeItem(storeKey)
        }
    }
    restoreCriteria() {
        if (!this.menu.id)      { return null }
        let cacheKey            = this.menu.id + ':' + this.top.name
        let storeKey            = 'criteria:' + cacheKey
        let criteria            = this.criteriaCache[cacheKey]
        if (is.object(criteria)) {
            return criteria
        }
        // remove rubbish data...
        delete this.criteriaCache[cacheKey]
        localStorage.removeItem(storeKey)
    }
    storeCriteria(criteria:any) {
        if (!this.menu.id)      { return }
        let cacheKey            = this.menu.id + ':' + this.top.name
        let storeKey            = 'criteria:' + cacheKey
        if (is.object(criteria)) {
            this.criteriaCache[cacheKey]
                                = criteria
            this.lsStore(storeKey, { criteria:criteria, created:new Date().valueOf() })
            return
        }
        // remove rubbish data...
        delete this.criteriaCache[cacheKey]
        localStorage.removeItem(storeKey)
    }
    //--------------------------------------------------------------------------
    private showDev_    :boolean= false
    get showDev(     )          { return this.showDev_  }
    set showDev(value)          { this.showDev_ = value }
    //--------------------------------------------------------------------------
    private log(...vals) {
//         let prefix              = '[globals]'
//         if (is.string(vals[0])) {
//             vals[0]             = prefix + ' ' + vals[0]
//         } else {
//             vals.unshift(prefix)
//         }
        console.log(...vals)
    }
    //--------------------------------------------------------------------------
    user                :any    = {}

    async setUserP(data:any={}) {
        if (data?.username == this.user.username) {
this.log('[setUserP] unchanged user...')
            return null
        }
this.log('[setUserP] new user...')
        this.user               = data || {}
        this.userIsDeveloper    = data.userIsDeveloper
        return this.loadMenuP()
    }

    get userIsDeveloper() {
        return this.user.userIsDeveloper == true
    }
    set userIsDeveloper(value) {
        if (value === true) {
            this.user.userIsDeveloper
                                = true
            global.VS           = this
            this.windowClass    = this.isDevel
                                    ? 'development-window'
                                    : 'production-window'
            console.log         = this.console_log
        } else {
            this.user.userIsDeveloper
                                = false
            delete global.VS
            this.windowClass    = ''
            console.log         = NOOP
        }
    }
    //--------------------------------------------------------------------------
    startP                      = () => new Promise((resolve, reject) => {
        this.socket.open((data:any) => {
            // this gets called every time the socket (re-)opens...
this.log('[startP.socketInit]', data)
            return (data == 'LOGIN'
                ? this.showLoginP()
                : this.setUserP(data)
            )
            .catch((err) => {
                this.log('[startP.socketInit]', err)
                return RESOLVE()
            })
            .then(() => resolve(null))
        })
    })
    //--------------------------------------------------------------------------
    private loginDisplayed_     = false
    get loginDisplayed()                { return this.loginDisplayed_  }
    set loginDisplayed(value:boolean)   { this.loginDisplayed_ = value }
    private showLoginP() {
        if (this.loginDisplayed) {
            return RESOLVE()
        }
        return this.setUserP()
        .then(() => this.callModalP('Login', {}) )
// .then((res) => { this.log('[LOGIN] res:', res); return RESOLVE(res) })
        .then ((res) => { return this.setUserP(res) })
        .catch((err) => { this.log('[showLoginP]', err) ; return RESOLVE() })
    }
    logout() {
        return this.setUserP()
        .then(() => this.socket.emitPayload('login:reset') ).catch(RESOLVE)
.then((res) => { this.log('[login:reset] res:', res); return RESOLVE(res) })
        .then(() => this.showLoginP() )
    }
    //==========================================================================
    // navigation
    //==========================================================================
    private instance    :any    = {}
    private dialog      :any    = {}
    private onClose     :any    = {}
    top                 :any    = { name:null }
    get isBusy()                { return this.dialog.BusyTag?.open }

    registerRoot(instance:any) {
        let name                = instance.constructor.name
// this.log(`[registerRoot] ${name}`)
        if (regexTest(name,'AlertTag','BusyTag','MainTag','MenuTag','ModalTag')) {
            this.instance[name] = instance
            let dialog          = window.document.querySelector('dialog.' + name)
            if (!dialog)        { return }
            this.dialog[name]   = dialog
            dialog.addEventListener('close', (evt) => instance.clearTag())
//             dialog.addEventListener('click', (evt) => {
//                 if (evt.clientX < 1 || evt.clientY < 1)
//                                 { return }
//                 let content     = dialog.querySelector('div.modal-content')
//                 if (!content)   { return }
//                 let dim         = content.getBoundingClientRect()
//                 if (evt.clientX < dim.left || evt.clientX > dim.right
//                  || evt.clientY < dim.top  || evt.clientY > dim.bottom
//                    ) {
// // this.log('DIALOG on click event:', evt)
// // this.log('DIALOG on click dimensions:', dim)
//                     this.onEsc()
//                 }
//             })
        }
    }
    setTop(instance:any) {
// this.log(`********** setTop ${this.top.name} -> ${instance.name}`)
        let previous            = this.top
        this.top                = instance
        return previous
    }
    unsavedData() {
        return this.top ? this.top.unsavedData() : false
    }
    isModalOpen() {
        return this.top.comp_type == 'modal'
    }
    //--------------------------------------------------------------------------
    onAll(event:any) {
// this.log('[onAll]', event)
    }
    //--------------------------------------------------------------------------
    onEsc() {
// this.log('[onEsc]')
        try {
            this.top.cancelFormatting_
                                = true
            this.top.onCancelP.call(this.top)
            .then ((res:any) => { this.log('onEsc:onCancelP res:', res) })
            .catch((err:any) => { this.log('onEsc:onCancelP err:', err) })
        } catch(err) {
            this.log('[onEsc] error:', err)
        }
        return false
    }
    //--------------------------------------------------------------------------
    onPrev(form:any) {
        try {
            let allButtons      = this.top.allButtons
            let func            = allButtons.prev ? allButtons.prev.CLICK
                                : allButtons.back ? allButtons.back.CLICK
                                                  : null
            if (func) {
                setTimeout(() => func.call(this, form))
            }
        } catch(err) {
// this.log('[onPrev] error:', err)
        }
        return false
    }
    //--------------------------------------------------------------------------
    onNext(form:any) {
        try {
            let func            = this.top.allButtons.next.CLICK
            if (func) {
                setTimeout(() => func.call(this, form))
            }
        } catch(err) {
// this.log('[onNext] error:', err)
        }
        return false
    }
    //--------------------------------------------------------------------------
    compOpts            :any    = null
    compStack           :any[]  = []
    get isStacked() {
        return this.compStack.length > 0
    }
    //--------------------------------------------------------------------------
    pushCompP(compname:string, ctx:any) {
        if (this.unsavedData()) { return REJECT('cancelled') }
        let compOpts            = this.compOpts
        if (compOpts) {
            compOpts.ctx        = this.top.ctx
            this.compStack.push(compOpts)
        }
        let opts        :any    = { compname:compname, ctx:ctx }
// MainTag.loadTagP returns immediately if successful...
        return this.loadMainTagP(opts)
        .catch((err:any) => {
            if (compOpts) {
                this.compStack.pop()
            }
            return REJECT(err)
        })
    }
    //--------------------------------------------------------------------------
    popCompP(check:boolean=true) {
        if (check && this.unsavedData())
                                { return REJECT('cancelled') }
        if (this.compStack.length < 1) {
            this.log('[popCompP] page stack is empty')
            return this.loadMainTagP()
        } else {
            return this.loadMainTagP(this.compStack.pop())
        }
    }
    //--------------------------------------------------------------------------
    breadcrumbs         :any[]  = []
    onMenuClick(id:any=0) {
this.log('global.onMenuClick id:', id)
        if (this.unsavedData()) { return }
        id                      = id || this.menu_top_id
        let menu                = clone(this.menu_detail[id])

        if (menu.id != id) {
            this.log('onMenuClick - invalid id:', id)
            if (id == this.menu_top_id) {
                this.menu       = {}
                this.setBreadcrumbs()
            } else {
                this.onMenuClick(this.menu_top_id)
            }
            return
        }

        if (menu.children) {
            // this is a sub-menu...
            menu.compname       = this.config.menu.compname
            menu.ctx            = this.config.menu.ctx
        } else {
            let parent          = this.menu_detail[menu.id_to]
            if (parent) {
                menu.children   = parent.children
            } else {
                menu.children   = this.menu.children
            }
        }
        this.menu               = menu
        this.compStack          = []
        this.loadMainTagP(menu).then(NOOP, NOOP)
    }
    setBreadcrumbs() {
        // breadcrumbs...
        this.breadcrumbs        = []
        let id                  = this.menu.id
        while (this.menu_detail[id]?.title) {
            this.breadcrumbs.unshift(id)
            id                  = this.menu_detail[id].id_to
        }
    }
    //--------------------------------------------------------------------------
    onLogoClick(evt) {
this.log('EVENT:', evt)
        if (evt.altKey || evt.button == 1) {
            this.updateURL()
        } else {
            this.loadMenuP().then(NOOP, NOOP)
        }
    }
    //--------------------------------------------------------------------------
    updateURL() {
        if (this.menu.id && is.string(this.menu.title)) {
            let id              = this.menu.id
            let title           = this.menu.title.replace(/[\s&]/g,'_')
            window.history.pushState(undefined, undefined,
                location.origin + `/?menu=${id}&name=${title}`
            )
        } else {
            window.history.pushState(undefined, undefined, location.origin)
        }
    }
    //==========================================================================
    // alertTag...
    //==========================================================================
    openAlertP(compname:string, ctx:any={}) {
        this.closeBusy()
        this.dialog.AlertTag.showModal()
        let opts        :any    = { compname:compname, ctx:ctx }
        return this.instance.AlertTag.loadTagP(opts)
    }
    //--------------------------------------------------------------------------
    closeAlert() {
this.log('closeAlert')
        this.dialog.AlertTag.close()
        setTimeout(() => this.instance.AlertTag.loadTagP().then(NOOP, NOOP), 200)
    }
    //==========================================================================
    // busyTag...
    //==========================================================================
    openBusy(from='??') {
// this.log('[openBusy]', from)
        if (this.dialog.BusyTag.open) {
            return this.log('[openBusy] already open...')
        }
        try {
            this.dialog.BusyTag.showModal()
        } catch(err) {
            this.log('[openBusy] err:', err)
        }
    }
    closeBusy(from='??') {
        try {
            this.dialog.BusyTag.close()
        } catch(err) {
            this.log('[closeBusy] err:', err)
        }
    }
    //==========================================================================
    // menuTag...
    //==========================================================================
    menu                :any    = {}
    menu_top_id         :number = 0
    menu_detail         :any    = {}
    //--------------------------------------------------------------------------
    async loadMenuP() {
this.log('[loadMenuP]...')
        let res                 = this.user.username
            ? await this.serverAction('menus:load').catch(() => RESOLVE(null) )
            : null
        let id                  = await this.initMenuP(res)
        this.onMenuClick(id)
        await global.delayTimer(100)

this.log('[loadMenuP] res:', res)

        return res

    }
    //--------------------------------------------------------------------------
    initMenuP(res:any={}) {
this.log('[initMenuP]', res)
        this.menu               = {}
        if (is.empty(res)) {
            this.menu_top_id    = 0
            this.menu_detail    = { 0:{ id:0 } }
            return RESOLVE(0)
        } else {
            this.menu_top_id    = res.top_id ? res.top_id : 0
            this.menu_detail    = res.detail ? res.detail : { 0:{ id:0 } }
            let match           = /\bmenu=(\d+)\b/.exec(window.location.search)
            return RESOLVE(match ? parseInt(match[1]) : this.menu_top_id)
        }
    }
    //==========================================================================
    // modalTag...
    //==========================================================================
    callModalP(compname:string, ctx:any={}) {
        this.closeBusy()
        this.dialog.ModalTag.showModal()
        let opts        :any    = { compname:compname, ctx:ctx }
        return this.instance.ModalTag.loadTagP(opts)
    }
    //--------------------------------------------------------------------------
    closeModal() {
this.log('closeModal')
        this.dialog.ModalTag.close()
        setTimeout(() => this.instance.ModalTag.loadTagP().then(NOOP, NOOP), 200)
    }
    //==========================================================================
    // mainTag...
    //==========================================================================
    loadMainTagP(opts:any={}) {
this.log('[loadMainTagP] opts:', opts)
        this.compOpts           = opts
        return this.instance.MainTag.loadTagP(opts)
// MainTag.loadTagP returns immediately if successful...
        .then((res) => {
            this.onResize()
            this.updateURL()
            this.setBreadcrumbs()
            return RESOLVE(res)
        })
        .catch((err) =>
            opts.compname == this.start_page
                ? REJECT(this.log(`unable to load "${this.start_page}" page`))
                : this.loadMainTagP({ compname:this.start_page, ctx:{} })
        )
    }
    //==========================================================================
    // serverAction...
    //==========================================================================
    serverAction(action:string, args:any=undefined) {
// this.log('[serverAction]')
        if (!action) {
            this.log('ACTION:', [ action ])
            return REJECT(`invalid action passed to emit: "${action}"`)
        }
        return this.socket.emitPayload(action, args)
        .then((res) => {
!/^lookup:/.test(action) && this.log(`[serverAction] ${action} result:`, res)
            return res?.err ? REJECT(res.err) : RESOLVE(res)
        })
        .catch((err) => {
            this.log(`[serverAction] ${action} error:`, err)
            if (err == 'LOGIN') {
                return this.showLoginP()
            } else {
                return REJECT(err)
            }
        })
    }
    //==========================================================================
}
//==============================================================================
export const glob       :Global = new Global()
//------------------------------------------------------------------------------
