//------------------------------------------------------------------------------
declare const RESOLVE, REJECT
//------------------------------------------------------------------------------
import  { Component                                 } from '@angular/core'
import  { ListDiv               , ListComp
        , ArtDiv                , TabbedComp
        , ArtService                                } from './service'
//------------------------------------------------------------------------------
@Component({selector:'ArtLst', template:ListDiv})
export class ArtLst extends ListComp {
    //--------------------------------------------------------------------------
    constructor( public service:ArtService ) { super() }
    comp_type                   = 'main'
    //--------------------------------------------------------------------------
    listDefn                    =
        { number        : {lab:'number'             , wide: 6}
        , quality       : {lab:'qlty'               , wide: 1}
        , profit_rank   : {lab:'rank'               , wide: 1, typ:'integer'}
        , brn_name      : {lab:'brand'              , wide:10}
        , sup_name      : {lab:'supplier'           , wide:10}
        , dsg_name      : {lab:'designer'           , wide:10}
        , matchcode     : {lab:'matchcode'          , wide:25}
        , wrg_name      : {lab:'group'              , wide:10}
        , typ_name      : {lab:'type'               , wide: 2}
        , brutto        : {lab:'gross'              , wide: 1, typ:'decimal'}
        , weight        : {lab:'weight (kg)'        , wide: 1, typ:'quantity'}
        , ext_number    : {lab:'external sku'       , wide: 4}
        , ean_number    : {lab:'ean number'         , wide: 4}
        , coo_name      : {lab:'origin'             , wide: 4}
        , abc_typ       : {lab:'ABC (qty)'          , wide: 1, cls:'center'}
        , status        : {lab:'status'             , wide: 2}
        , shelves       : {lab:'shelves'            , wide:10, typ:'textarea'}
        }
    //--------------------------------------------------------------------------
    dontLoadAll         :boolean= true
    onLoad_task                 = 'load'
    //--------------------------------------------------------------------------
    onInitP() {
        this.setHeader          ('=menu='           )
        let listDefn            = { ...this.listDefn }
        const set_dft           = (ctx_key, dft_key) => {
            if (this.ctx[ctx_key]) {
                delete listDefn[dft_key]
                this.dft[dft_key]   = this.ctx[ctx_key]
            }
        }
        set_dft('type'      , 'typ_name')
        set_dft('brand'     , 'brn_name')
        set_dft('designer'  , 'dsg_name')
        set_dft('supplier'  , 'sup_name')
        set_dft('group'     , 'wrg_name')
        if (this.ctx.labelsOnly) {
            this.dft.typ_name   = 'art'
            delete listDefn.typ_name
            delete listDefn.profit_rank
            delete listDefn.wrg_name
            delete listDefn.brutto
            delete listDefn.weight
            delete listDefn.status
            delete listDefn.shelves
            this.defineList     (listDefn           )
            this.buttonSelAll   (this.onSelectAllP  )
            this.buttonInvSel   (this.onInvertSelP  )
            this.makeButton     (this.onSkuLabelsP  , 'print labels')
            this.makeButton     (this.onPrcLabelsP  , 'print price labels')
            this.onCriteria     (this.onCriteriaP   )
            this.listClick      (this.onSelectItmP  )
            this.listDclick     (this.onSkuLabelP      )
        } else {
            this.defineList     (listDefn           )
//             this.buttonNew      (this.onNewP        )
            this.buttonSelAll   (this.onSelectAllP  )
            this.buttonInvSel   (this.onInvertSelP  )
            this.buttonPrint    (this.onPrintP      )
            this.makeButton     (this.onSkuLabelsP  , 'print labels')
            this.makeButton     (this.onPrcLabelsP  , 'print price labels')
            this.makeButton     (this.onDownloadKpiP, 'download kpi')
//             this.makeButton     (this.onDlsUpdateP  , 'update DLS'  )
            this.makeButton     (this.onSetFlagsP   , 'activate'    )
            this.makeButton     (this.onSetFlagsP   , 'deactivate'  )
            this.onCriteria     (this.onCriteriaP   )
            this.listClick      (this.onSelectItmP  )
            this.listDclick     (this.onUpdateP     )
        }

// new select uses details from prd and vrn where possible...
this.dft.use_new = this.ctx.use_new
if (this.glob.userIsDeveloper && this.emptyCriteria) {
    this.criteria.number = '010012*'
}

        return this.loadAndFocusP()
    }
    //--------------------------------------------------------------------------
    onUpdateCompName            = 'Art'
    onUpdateContext(idx:number) {
        return { id:this.list[idx].id }
    }
    //--------------------------------------------------------------------------
    onSetFlagsP(key:string, values:any) {
        return this.getSelectedIdsP()
        .then((ids:any) => {
            return this.busyServiceEmitP('setFlags',
                { key           : key
                , ids           : ids
                , TIMEOUT       : ids.length * 2
                })
        })
        .then(() => this.loadP() )
        .then(() => this.setFocusP(this.messageSaved()) )
    }
    //--------------------------------------------------------------------------
    onPrintP(key:string, values:any) {
        return this.getSelectedIdsP()
        .then((ids:any) => {
            let ctx             = { name            : 'Art018'
                                  , art_ids         : ids
                                  }
            return this.callComponent('RptModal', ctx)
            .then((res:any) => this.onPrintDoneP(key, res) )
        })
    }
    onPrintDoneP(key:string, res:any) {
        return RESOLVE()
    }
    //--------------------------------------------------------------------------
    onSkuLabelP(idx:number) {
        return this.onSkuLabels([ this.list[idx] ])
    }
    //--------------------------------------------------------------------------
    onSkuLabelsP(key:string, values:any) {
        return this.getSelectedItemsP()
        .then((items:any) => this.onSkuLabels(items) )
    }
    //--------------------------------------------------------------------------
    onSkuLabels(items:any) {
        items                   = items.map((item) => (
            { number            : item.number
            , matchcode         : item.matchcode
            , code              : item.quality == 'A' ? item.ean_number : item.code
            }
        ))
        let ctx                 =
            { name              : 'sku labels'
            , items             : items
            }
        return this.callComponent('PrtLabModal', ctx)
        .then((res:any) => this.onSkuLabelsDone() )
    }
    onSkuLabelsDone() {
        return RESOLVE()
    }
    //--------------------------------------------------------------------------
    onPrcLabelP(idx:number) {
        return this.onPrcLabels([ this.list[idx] ])
    }
    //--------------------------------------------------------------------------
    onPrcLabelsP(key:string, values:any) {
        return this.getSelectedItemsP()
        .then((items:any) => this.onPrcLabels(items) )
    }
    //--------------------------------------------------------------------------
    onPrcLabels(items:any) {
        items                   = items.map((item) => (
            { number            : item.number
            , matchcode         : item.matchcode
            , code              : item.quality == 'A' ? item.ean_number : item.code
            , brutto            : item.brutto     || null
            }
        ))
        let ctx                 =
            { name              : 'sku price labels'
            , items             : items
            }
        return this.callComponent('PrtLabModal', ctx)
        .then((res:any) => this.onPrcLabelsDone() )
    }
    onPrcLabelsDone() {
        return RESOLVE()
    }
    //--------------------------------------------------------------------------
    onDownloadKpiP(key:string, values:any) {
        return this.getSelectedIdsP({ allowNone:true })
        .then((ids:any) => {
            return this.busyServiceEmitP('download_kpis',
                { key           : key
                , art_ids       : ids
                , TIMEOUT       : ids.length * 1
                })
        })
        .then((res) => {
            this.saveFile(res, 'kpi.csv', 'text/csv')
            return this.setFocusP()
        })
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
@Component({selector:'Art', template:ArtDiv})
export class Art extends TabbedComp {
    //--------------------------------------------------------------------------
    constructor( public service:ArtService ) { super() }
    //--------------------------------------------------------------------------
    onInitP() {
        this.service.initTabs(this.ctx.id, 'dtl')
        this.ctx.art_id         =
        this.dft.art_id         = this.ctx.id
        this.cache              = {}
        return RESOLVE()
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
