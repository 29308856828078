//------------------------------------------------------------------------------
declare const RESOLVE, REJECT, regexTest
//------------------------------------------------------------------------------
import  { Component                                 } from '@angular/core'
import  { ItemDiv, ItemComp, OrdService             } from './service'
//------------------------------------------------------------------------------
@Component({selector:'OrdDtl', template:ItemDiv})
export class OrdDtl extends ItemComp {
    //--------------------------------------------------------------------------
    constructor( public service:OrdService ) { super() }
    comp_type                   = 'maintab'
    //--------------------------------------------------------------------------
    itemDefn            :any    =
        { HIDE          : ['id','update_date','ols_id','typ_id','typ','cus_id','cur_id','chn_id','vnd_id','wrk_id','stt_id']
        , knt_name      : {lab:'account name'       , wide: 4, rtn:this.kntSearchP}
        , knt_search    : {lab:'account search'     , wide: 2, btn:this.kntSearchP}
        , knt_num       : {rdo:'account number'     , wide: 2}
        , wsale_sw      : {rdo:'wholesale'          , wide: 1, typ:'boolean'}
        , monthly       : {rdo:'monthly'            , wide: 1, typ:'boolean'}
        , dont_email    : {opt:'dont email'         , wide: 2, typ:'boolean'}

        , descr         : {opt:'description'        , wide:12               , newline:true}

        , date          : {lab:'date'               , wide: 2, typ:'datetime',newline:true}
        , ext_number    : {opt:'reference'          , wide: 2}
        , lnd_id        : {lab:'delivery country'   , wide: 2, cod:'lnd'    }
        , cur_typ       : {rdo:'currency'           , wide: 2}
        , rfr_code      : {opt:'referrer code'      , wide: 2}
        , pmo_code      : {opt:'promo code'         , wide: 2}

        , pay_id        : {lab:'payment type'       , wide: 2, cod:'pay'    , newline:true}
        , pay_ref       : {opt:'payment reference'  , wide: 2}
        , netto         : {rdo:'netto'              , wide: 2, typ:'decimal'}
        , brutto        : {rdo:'brutto'             , wide: 2, typ:'decimal'}
        , acc_netto     : {rdo:'netto (€)'          , wide: 2, typ:'decimal'}
        , acc_brutto    : {rdo:'brutto (€)'         , wide: 2, typ:'decimal'}

        , typ_name      : {rdo:'type'               , wide: 2               , newline:true}
        , number        : {rdo:'number'             , wide: 2}
        , chn_name      : {rdo:'channel'            , wide: 2}
        , vnd_name      : {rdo:'owner'              , wide: 2}
        , wrk_name      : {rdo:'workflow'           , wide: 2}
        , code          : {rdo:'code'               , wide: 2, typ:'integer'}


        , due_days      : {opt:'due days'           , wide: 2, typ:'integer', newline:true}
        , dsc_days      : {opt:'disc. days'         , wide: 2, typ:'integer'}
        , dsc_pct       : {opt:'disc. %'            , wide: 2, typ:'percent'}
        , stt_name      : {rdo:'status'             , wide: 2}
        , invoiced      : {rdo:'invoiced'           , wide: 1, typ:'boolean'}
        , booked        : {rdo:'booked'             , wide: 1, typ:'boolean'}
//         , skip_1        : {rdo:''                   , wide: 1, typ:'skip'   }

        , rsr_id        : {rdo:'warehouse'          , wide: 2, cod:'rsr_loc', newline:true}
        , stk_typ       : {rdo:'stock relevance'    , wide: 2               }

        , events_e      : {rdo:'errored events'     , wide: 6, newline:true}
        , events_n      : {rdo:'unprocessed events' , wide: 6}
        }
    //--------------------------------------------------------------------------
    onInitP() {
        let itemDefn            = this.clone(this.itemDefn)
        if (this.ctx.canEdit) {
            itemDefn            = this.setAccess(itemDefn)
        } else {
            itemDefn.RDO        = true
        }
        this.defineItem         (itemDefn           )

        if (regexTest(this.ctx.typ, 'cpa', 'ior')) {
            this.makeButton     (this.onGetDatafileP
                                              , 'download details', '', 'tr')
        }
        this.makeButton         (this.onPrintP,'print', '', 'tr')
        this.makeButton         (this.onWarehouseP,'change warehouse', '', 'tr')
        this.eventButtons       (this.ctx.events    )

        if (this.ctx.canEdit) {
            this.buttonOk       (this.onSubmitP     )
            this.formSubmit     (this.onSubmitP     )
        }

        let item                = this.cache.vrg
        if (item && !this.ctx.ord_id) {
            this.item           = this.readResult(item)
            return this.readDoneP()
            .then(() => this.setFocusP() )
        } else {
            return this.readP(this.ctx)
            .then(() => this.setFocusP() )
        }
    }
    //--------------------------------------------------------------------------
    setAccess(def:any) {
        let ctx                 = this.ctx
        if (ctx.ord_id) {
            def.knt_name.rdo    = true
            def.knt_search.typ  = 'skip'
            def.lnd_id.rdo      = true
            def.pay_id.rdo      = def.pay_id.opt = true
            return def
        }
        let typ                 = ctx.typ && ctx.typ.toLowerCase
                                    ? ctx.typ.toLowerCase()
                                    : null
        switch (typ) {
          case 'ior':
          case 'idl':
            ctx.knt_typ         = ctx.knt_typ   || 'sup'
            ctx.knt_subtyp      = ''
            def.pay_id.rdo      = def.pay_id.opt = true
            break
          default:
            ctx.knt_typ         = 'cus|emp'
            ctx.knt_subtyp      = ''
            break
        }
        return def
    }
    //--------------------------------------------------------------------------
    readResult(res:any) {
        this.service.initTabs(res.id, 'dtl')
        if (res.ols_id) {
            this.enableField    ('rfr_code' )
            this.enableField    ('pmo_code' )
        } else {
            this.disableField   ('rfr_code' )
            this.disableField   ('pmo_code' )
        }

        if (regexTest(res.stt_typ, 'cmp', 'inv', 'exe')) {
            this.ctx.canEdit    = false
            this.disableField   ('ALL'      )
            this.hideButton     ('ALL'      )
        } else if (this.ctx.canEdit) {
            if (regexTest(res.typ, 'ord') || regexTest(res.stt_typ, 'cnc')) {
                this.enableField('pay_id')
            }
//             if (regexTest(res.typ, 'ord', 'dlv', 'ior', 'idl')) {
//                 this.enableField('rsr_id')
//             } else {
//                 this.disableField('rsr_id')
//             }
        }
        this.service.setHeader(res)
        this.setCache(res)
        return res
    }
    //--------------------------------------------------------------------------
    setCache(vrg:any) {
// this.log('setCache:', vrg)
        let old                 = this.cache.vrg || {}
        if (vrg.lnd_id != old.lnd_id
        ||  vrg.cur_id != old.cur_id) {
            this.cache          = {}
        }
        this.cache.vrg          = vrg
    }
    //--------------------------------------------------------------------------
    onSubmitP(key:string, values:any) {
// this.log('onSubmitP:', values)
        if (this.ctx.ord_id) {
            return this.onSaveP   (key, values)
        } else {
            return this.onSetBaseP(key, values)
        }
    }
    onSetBaseP(key:string, values:any) {
        return this.busyServiceEmitP('set_vrg_base', values)
        .then((res:any) => {
            this.item           = res
            this.setCache(res)
            this.service.setHeader(res)
            if (!this.service.revealTab('adr')
             && !this.service.revealTab('pst')
               ) {
                this.service.showValidTabs()
            }
            this.messageValid()
            return RESOLVE()
        })
    }
    onSaveP(key:string, values:any) {
        return this.busyServiceEmitP('save', values)
        .then((res:any) => {
            this.item           = res
            this.setCache(res)
            this.service.setHeader(res)
            this.messageSaved()
            return RESOLVE()
        })
    }
    //--------------------------------------------------------------------------
    onGetDatafileP(key:string, values:any) {
        if (!this.ctx.ord_id) {
            return RESOLVE()
        }
        return this.downloadP({ filename:this.item.number + '.xlsx' },
            { name              : 'details'
            , task              : 'get_datafile'
            })
    }
    //--------------------------------------------------------------------------
    onPrintP(key:string, values:any) {
        if (!this.ctx.ord_id) {
            return RESOLVE()
        }
        this.messageText('print request submitted')
        return this.downloadP({ ord_ids:[ this.ctx.ord_id ]},
            { name              : 'print'
            , task              : 'print'
            })
        .then(() => this.onBackP(false) )
    }
    //--------------------------------------------------------------------------
    onEventP(key:string, values:any) {
        if (!this.ctx.ord_id) {
            return RESOLVE()
        }
        return this.busyServiceEmitP('event',
            { event             : key
            , ord_ids           : [ this.ctx.ord_id ]
            })
        .then(() => this.onBackP(false) )
    }
    //--------------------------------------------------------------------------
    kntSearchP(key:string, values:any) {
        let knt_name            = (key == 'knt_name') ? values : values.knt_name
        let ctx                 =
            { typ               : this.ctx.knt_typ
            , subtyp            : this.ctx.knt_subtyp
            , vendor            : this.ctx.vendor
            , name              : knt_name
            }
        return this.callComponent('SelectKnt', ctx)
        .then((res:any) =>
            this.setItemValuesP(
                { cus_id        : res.id
                , knt_name      : res.name
                , knt_num       : res.number
                , wsale_sw      : res.wsale_sw
                , total         : res.wsale_sw
                                    ? this.item.netto
                                    : this.item.brutto
                })
            .then(() => {
                this.messageDone('account selected')
                return this.setFocusP('knt_name')
            })
        )
        .catch((err) => {
            this.messageError('nothing selected')
            return this.setFocusP('knt_name')
        })
    }
    //--------------------------------------------------------------------------
    async onWarehouseP(key:string, values:any) {
        if (!this.ctx.ord_id)   { return }
        let res                 = await this.callComponent('ParamsModal',
            { header            : 'select new warehouse...'
            , itemDefn          :
                { rsr_id        : {lab:'warehouse', cod:'rsr'} }
            , values            :
                { rsr_id        : this.item.rsr_id }
            }
        )
        if (!res)               { return }
        if (res.rsr_id == this.item.rsr_id) {
            this.messageError('selected warehouse same as original')
            return this.setFocusP()
        }
        await this.busyServiceEmitP('event',
            { event             : 'rsr'
            , ord_ids           : [ this.ctx.ord_id ]
            , data              :
                { rsr_id_old    : this.item.rsr_id
                , rsr_id_new    : res.rsr_id
                }
            }
        )
        return this.onBackP(false)
//         this.messageDone('warehouse change scheduled')
//         return this.setFocusP()
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
