//------------------------------------------------------------------------------
declare const RESOLVE, REJECT
//------------------------------------------------------------------------------
import  { Component                                 } from '@angular/core'
import  { ComponentFactoryResolver                  } from '@angular/core'
import  { ComponentRef                              } from '@angular/core'
import  { ViewChild                                 } from '@angular/core'
//------------------------------------------------------------------------------
import  { AlertElem, BaseComp, AlertDiv             } from './service'
//------------------------------------------------------------------------------
@Component({selector:'AlertTag', template:AlertDiv})
export class AlertTag extends BaseComp {
    //--------------------------------------------------------------------------
    constructor( private cfr:ComponentFactoryResolver ) {
        super()
        this.glob.registerRoot(this)
    }
    comp_type                   = 'modal'
    //--------------------------------------------------------------------------
    @ViewChild(AlertElem, {static:false})
    element             :AlertElem
    compRef             :ComponentRef<Component>

//     clearTag() {
//         if (this.compRef)       { this.compRef.destroy() }
//         this.compRef            = null
//     }

    clearTag() {
        try {
            if (this.compRef) {
                this.compRef.destroy()
            }
            this.compRef        = null
        } catch(err) {
            this.log('clearTag:', err)
        }
    }

    loadTagP(opts:any={}) {
this.log('loadTagP opts:', opts)
        if (!opts.compname)     { return REJECT(this.clearTag()) }
        let comp                = this.glob.components[opts.compname]
        if (comp === undefined) {
            this.log(`********** component "${opts.compname}" not found`)
            return REJECT()
        }
        try {
            this.clearTag()
            let container       = this.element.viewContainerRef
            container.clear()
            this.compRef        = container.createComponent(this.cfr.resolveComponentFactory(comp))
            let instance        = <AlertTag>this.compRef.instance
            return instance.onLoadInstanceP(opts, true)
            .then ((res) => { this.log('AlertTag.resolve'); return RESOLVE(res) })
            .catch((err) => { this.log('AlertTag.reject' ); return REJECT (err) })
        } catch(err) {
            this.log('********** loadTagP err:', err)
            return REJECT()
        }
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
